<template>
  <a-modal
    :title="title"
    :visible="visible"
    :destroyOnClose="true"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="$emit('cancel')"
    width="700px"
  >
    <a-form-model
      class="formInfo"
      ref="form"
      layout="horizontal"
      :rules="rules"
      :model="formData"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      labelAlign="right"
    >
      <a-form-model-item label="通知名称" prop="notificationName">
        <a-select
          v-model="formData.notificationName"
          style="width: 240px"
          :showSearch="true"
          :filter-option="filterOption"
          @select="whenSelectName"
        >
          <a-select-option
            v-for="item in dicts.notificationNames"
            :key="item.name"
          >
            {{ item.displayName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="模板名称" prop="displayName">
        <a-input
          type="text"
          placeholder="请输入通知模板名称如：新增任务短信通知"
          v-model="formData.displayName"
          autocomplete="off"
          style="width: 240px"
        />
      </a-form-model-item>
      <a-form-model-item label="模板种类">
        <a-select v-model="formData.templateCategory" style="width: 240px">
          <a-select-option :key="1" :value="1"> 纯文本 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="通知类型">
        <a-checkbox-group v-model="formData.notificationCarrierArray">
          <a-checkbox
            v-for="item in dicts.carrierTypes"
            :key="item.name"
            :value="item.name"
          >
            {{ item.displayName }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="通知标题">
        <a-input
          id="content"
          placeholder="请输入通知标题"
          v-model="formData.title"
          autocomplete="off"
        />
      </a-form-model-item>
      <a-alert type="info" :closable="true">
        <div slot="message">
          可点击按钮在相关内容中快捷加入相关占位符
          <hr />
          支持
          <a target="_blank" href="https://github.com/scriban/scriban">
            Scriban
          </a>
          语法。
        </div>
      </a-alert>
      <a-card :title="`通知内容`">
        <a-button
          @click="btngClc"
          v-for="contentPHD in contentPHDs"
          :key="contentPHD.id"
          :title="contentPHD.contentPlacholder"
          >{{ contentPHD.title }}</a-button
        >
        <a-input
          type="textarea"
          id="content"
          placeholder=""
          v-model="formData.content"
          autocomplete="off"
          style="height: 135px"
          ref="contentTextA"
        />
      </a-card>
    </a-form-model>
  </a-modal>
</template>

<script>
import saasAdmin from "/src/api/saasAdmin.js";
const {
  create,
  update,
  getNotTypeEnums,
  getNotSeverityEnums,
  getAllNotificationNames,
  getAllNotificationCarriers,
} = saasAdmin.notificationTpl;
const defaultModel = {
  notificationCarrierArray: ["Insite"],
  templateCategory: "Text",
};
export default {
  name: "SaasAdminNotificationTemplateEdit",
  props: {
    visible: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
    templateCategorys: { type: Array, default: () => [] },
  },
  data() {
    return {
      hasChild: false,
      selectRow: {},
      confirmLoading: false,
      formData: {},
      contentPHDs: [
        { id: 1, title: "公司名", contentPlacholder: "company.Name" }, //公司名称后续再处理
        { id: 2, title: "发送人", contentPlacholder: "formUser" }, //等完善信息后再取消注释
        { id: 3, title: "被通知人", contentPlacholder: "receiver.Name" },
        { id: 4, title: "验证码", contentPlacholder: "smsCode" },
        { id: 5, title: "任务名", contentPlacholder: "task.Name" },
      ],
      dicts: {
        severitys: [],
        notificationNames: [],
        types: [],
        carrierTypes: [],
      },
      /** 表单验证规则 */
      rules: {
        displayName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        notificationName: [
          { required: true, message: "通知类型不可为空", trigger: "blur" },
        ],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadDict();
  },
  methods: {
    whenSelectName() {
      this.$nextTick(() => {
        // 选择了通知类型，可以带出显示名、通知对象、模板类型
        const item = this.dicts.notificationNames.find(
          (x) => x.name === this.formData.notificationName
        );
        if (item) {
          if (item.displayName) this.formData.displayName = item.displayName;
          if (item.type) this.formData.type = item.type;
          if (item.severity) this.formData.severity = item.severity;
          this.$forceUpdate();
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    loadDict() {
      getNotTypeEnums().then((res) => {
        this.dicts.types = res;
      }),
        getNotSeverityEnums().then((res) => {
          this.dicts.severitys = res;
        });
      getAllNotificationNames().then((res) => {
        this.dicts.notificationNames = res;
      });
      getAllNotificationCarriers().then((res) => {
        this.dicts.carrierTypes = res;
      });
    },
    handleOk() {
      if (!this.formData.content) {
        return this.$message.error("请填写通知内容!");
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.form.id) {
            update(this.form.id, this.formData).then(() => {
              this.confirmLoading = false;
              this.$emit("saved");
              this.$message.success("修改成功!");
            });
          } else {
            create(this.formData).then(() => {
              this.confirmLoading = false;
              this.$emit("saved");
              this.$message.success("添加成功!");
            });
          }
        }
      });
    },

    //获取光标位置
    getPositionForTextArea(ctrl) {
      let CaretPos = 0;
      if (ctrl.selectionStart || ctrl.selectionStart == "0") {
        // Firefox support
        CaretPos = ctrl.selectionStart;
      }
      return CaretPos;
    },

    //在光标处拼接字符串
    btngClc(e) {
      var addText = e.target.title;
      /**
       * 如果后面改用下拉框，那么要添加的文本就是如下，记得btngClc参数加上btngClc(value,option)
       * var addText=option.componentOptions.children[0].text.trim();*/
      // let position1 = document.getElementsByTagName("textarea")[0];
      let position = this.$refs.contentTextA.$el;
      let pos = this.getPositionForTextArea(position);
      let y = position.value;
      this.frontString = y.substring(0, pos);
      this.afterString = y.substring(pos, this.formData.content.length);
      this.formData.content =
        this.frontString + "{{" + addText + "}}" + this.afterString;
    },
  },
  computed: {
    title() {
      return this.formData.id
        ? this.readonly
          ? "查看-通知模板"
          : "修改-通知模板"
        : "新增-通知模板";
    },
  },
  watch: {
    form: {
      handler() {
        this.formData = Object.assign({}, defaultModel, this.form);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
