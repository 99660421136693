<template>
  <div class="bgStyle">
    <div class="title">通知模板</div>
    <div>
      <a-space size="8">
        <a-input-search
          placeholder="查询条件,包括类别、名称"
          style="width: 220px; margin-right: 20px"
          v-model="filter"
          @search="onSearch"
        />
        <a-button type="primary" @click="whenAddRow" style="margin-left: 20px">
          新增通知模板
        </a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :templateCategorys="templateCategorys"
      class="table"
      rowKey="id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="whenView(record)">详情</a>
        <a-divider type="vertical" />
        <a @click="whenEditRow(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="whenDeleteRow(record)">删除</a>
      </template>
    </a-table>

    <NotTplEdit
      :templateCategorys="templateCategorys"
      :visible="editCtrl.visible"
      :readonly="editCtrl.readonly"
      :form="editCtrl.form"
      @saved="whenSavedData"
      @cancel="editCtrl.visible = false"
    />
  </div>
</template>

<script>
import NotTplEdit from "./components/EditDialog.vue";
import saasAdmin from "/src/api/saasAdmin.js";
const { getList, deleteNotTpl, getTemplateCategorys } =
  saasAdmin.notificationTpl;
//import axios from 'axios';

const columns = [
  {
    title: "通知名称",
    dataIndex: "notificationDisplayName",
  },
  {
    title: "模板名称",
    dataIndex: "displayName",
  },
  {
    title: "通知类型",
    dataIndex: "notificationCarrierName",
  },
  {
    title: "模板种类",
    dataIndex: "templateCategoryName",
  },
  // {
  //   title: "模板类型",
  //   dataIndex: "typeName",
  //   ellipsis: true,
  // },
  // {
  //   title: "通知严重级别",
  //   dataIndex: "severityName",
  //   ellipsis: true,
  // },
  {
    title: "标题",
    dataIndex: "title",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: { NotTplEdit },
  data() {
    return {
      data: [],
      filter: undefined,
      columns,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      templateCategorys: [],
      editCtrl: {
        visible: false,
        readonly: false,
        form: {},
      },
    };
  },
  created() {
    this.loadDataAsync();
    this.laodDicts();
  },
  methods: {
    whenSavedData() {
      this.editCtrl.visible = false;
      this.loadDataAsync();
    },
    laodDicts() {
      getTemplateCategorys().then((re) => {
        this.templateCategorys = re;
      });
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.loadDataAsync();
    },
    loadDataAsync() {
      var parm = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
        filter: this.filter,
      };
      getList(parm).then((res) => {
        this.pagination.total = res.totalCount;
        this.data = res.items;
      });
    },
    onSearch() {
      this.loadDataAsync();
    },
    whenAddRow() {
      this.editCtrl.form = {};
      this.editCtrl.readonly = false;
      this.editCtrl.visible = true;
    },
    whenView(row) {
      this.editCtrl.form = { ...row };
      this.editCtrl.readonly = true;
      this.editCtrl.visible = true;
    },
    whenEditRow(row) {
      this.editCtrl.form = { ...row };
      this.editCtrl.readonly = false;
      this.editCtrl.visible = true;
    },
    whenDeleteRow(row) {
      let self = this;
      this.$confirm({
        title: "确认提示",
        content: `是否确认要删除该通知模板？`,
        onOk() {
          return new Promise((resolve) => {
            deleteNotTpl(row.id)
              .then(() => {
                self.$message.success("删除成功! ");
              })
              .catch(() => {
                self.$message.error("删除失败");
              })
              .finally(() => {
                self.onSearch();
                resolve();
              });
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
</style>
